var site = site || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };
(function ($) {
  Drupal.behaviors.gnavAccountV1 = {
    attach: function (context) {
      var $html = $('html');
      var $template = $('.js-gnav-account', context);
      var $trigger = $('#gnav_util_trigger_account', context);
      var $accountContent = $('.js-gnav-account-content', $template);
      var $headerMain = $('.site-header__content', context);
      var $formContainer = $('.gnav-account__content-container-forms', $accountContent);
      var token;
      var firstName = site.userInfoCookie.getValue('first_name');

      $trigger.change(function () {
        if (this.checked) {
          $html.toggleClass('active-utility-overlay', true);
          $html.toggleClass('active-gnav', false);
          $(document).trigger('loadFacebookScript');
          setOverlayHeight();
        }
      });

      if (parseInt(site.userInfoCookie.getValue('signed_in')) === 0) {
        $accountContent.removeClass('is-signed-in');
      } else if (parseInt(site.userInfoCookie.getValue('signed_in')) === 1) {
        $accountContent.addClass('is-signed-in');
      }

      $accountContent.each(function () {
        var $block = $(this);
        var $toggleSigninTrigger = $('.js-gnav-account-content-header-title-toogle-signin', $block);
        var $toggleRegisterTrigger = $('.js-gnav-account-content-header-title-toogle-register', $block);
        var $registerForm = $('.js-gnav-account-content-container-forms-register-container-form', $block);
        var $signInForm = $('.js-gnav-account-content-container-forms-signin-container-form', $block);
        var $inputToken = $('.js-gnav-account-content-container-forms-register-container-form-token', $registerForm);
        var $userName = $('.js-gnav-account-content-container-forms-account-links-name', $block);

        $userName.html(firstName);
        $inputToken.val(token);

        $registerForm.once().on('submit', function (event) {
          var error = false;

          error = validateInputsRequired($(this));
          if (error) {
            event.preventDefault();
          }
        });

        $signInForm.once().on('submit', function (event) {
          var error = false;

          error = validateInputsRequired($(this));
          if (error) {
            event.preventDefault();
          }
        });

        $toggleSigninTrigger.once().on('click', function (event) {
          event.preventDefault();
          $block.removeClass('register-active');
          $block.addClass('signin-active');
          setOverlayHeight();
        });

        $toggleRegisterTrigger.once().on('click', function (event) {
          event.preventDefault();
          $block.removeClass('signin-active');
          $block.addClass('register-active');
          setOverlayHeight();
        });
      });

      function validateInputsRequired($element) {
        var error = false;

        if ($element.length === 0) {
          return true;
        }
        $element.find('input').each(function () {
          var $this = $(this);

          if ($this.hasClass('required')) {
            if (!$this.val() || ($this.attr('type') === 'checkbox' && !$this.prop('checked'))) {
              error = true;
              $this.addClass('error');
            } else {
              $this.removeClass('error');
            }
          }
        });

        return error;
      }

      function setOverlayHeight() {
        var siteHeaderHeight = $headerMain.outerHeight(true);
        var accountHeaderHeight = $template.find('.js-gnav-account-content-header').outerHeight(true);
        var overlayHeight = $(window).height() - siteHeaderHeight;
        var formHeight = overlayHeight - accountHeaderHeight;

        // set height of entire overlay to window height, less gnav offset
        $accountContent.height('auto');
        $accountContent.css('max-height', overlayHeight);

        // set height and max-height for form content to scroll
        $formContainer.height('auto');
        $formContainer.css('max-height', formHeight);
      }
    }
  };
})(jQuery);
